import { Button } from '@finn/design-system';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import React, { useContext } from 'react';

import { AddToCartContext } from '~/components/ProductDetails/AddToCartProvider';

interface IProps {
  title: string;
  buttonClassName?: string;
  isMainButton?: boolean;
}
const AddToCart: React.FunctionComponent<IProps> = ({ title }) => {
  const { isLoading, onAddToCart } = useContext(AddToCartContext);

  const handleClick = () => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location: 'comparison',
    });

    onAddToCart();
  };

  return (
    <Button
      data-cy="submit"
      loading={isLoading}
      onClick={handleClick}
      variant="outlineWhite"
    >
      {title}
    </Button>
  );
};

export default AddToCart;
