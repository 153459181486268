import { Button } from '@finn/design-system';
import { cn } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { IComparisonTableFooterLinkAction } from '~/types/comparisonTable';

type Props = {
  fullWidth?: boolean;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  button: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
  },
}));

interface IProps {
  action: IComparisonTableFooterLinkAction;
  fullWidth?: boolean;
}
const LinkFooterAction: React.FunctionComponent<IProps> = ({
  action,
  fullWidth,
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <Button
      variant="outlineWhite"
      href={action.href}
      className={cn(classes.button)}
    >
      {action.title}
    </Button>
  );
};

export default LinkFooterAction;
